
















































import BoostsCondition from "@/includes/logic/Modules/models/conditions/BoostsCondition/BoostsCondition";
import { BoostsSourceEnum } from "@/includes/logic/Modules/models/conditions/BoostsCondition/types";
import { InputSetups } from "@/mixins/input-setups";
import TariffsTagsHelper from "@/mixins/TariffsTagsHelper";

import SelectInput from 'piramis-base-components/src/components/Pi/fields/SelectInput/SelectInput.vue';
import TextInput from "piramis-base-components/src/components/Pi/fields/TextInput/TextInput.vue";
import SwitchInput from "piramis-base-components/src/components/Pi/fields/SwitchInput/SwitchInput.vue";
import NestedContent from "piramis-base-components/src/components/Pi/components/NestedContent.vue";
import { FieldData, SelectOptionData } from "piramis-base-components/src/components/Pi/types";

import { Component, Mixins, VModel } from 'vue-property-decorator';

@Component({
  data() {
    return {
      BoostsSourceEnum
    }
  },
  components: {
    SelectInput,
    TextInput,
    SwitchInput,
    NestedContent
  }
})
export default class BoostsConditionView extends Mixins(InputSetups, TariffsTagsHelper) {
  @VModel() model!: BoostsCondition

  get sourcePrefix() {
    const basePrefix = 'boosts_condition_'
    if (this.model.required) {
      return "required_true_" + basePrefix
    }

    return "required_false_"  + basePrefix
  }

  get requiredConditionOptions(): Array<SelectOptionData> {
    return [
      {
        label: this.$t('boosts_condition_required_true').toString(),
        value: true
      },
      {
        label: this.$t('boosts_condition_required_false').toString(),
        value: false
      },
    ]
  }

  get boostsConditionOptions(): Array<SelectOptionData> {
    return [
      {
        label: this.$t('boosts_condition_source_group').toString(),
        value: BoostsSourceEnum.Group
      },
      {
        label: this.$t('boosts_condition_source_linked_channel').toString(),
        value: BoostsSourceEnum.LinkedChannel,
        tags: this.advancedLicenseTag,
        disabled: !!this.advancedLicenseTag
      },
      {
        label: this.$t('boosts_condition_source_custom').toString(),
        value: BoostsSourceEnum.Custom,
        tags: this.brandTag,
        disabled: !!this.brandTag
      },
    ]
  }

  customSetter(args: FieldData) {
    args.setter = (value: string) => {
      const parsedValue = Number.parseInt(value)

      if (!Number.isNaN(parsedValue)) {
        this.$set(args.model, args.key, parsedValue)
      } else {
        this.$set(args.model, args.key, '')
      }
    }

    return args
  }

  sourceSetter(args: FieldData) {
    args.setter = (value: BoostsSourceEnum) => {
      if (value !== BoostsSourceEnum.Custom) {
        this.$set(args.model, 'custom', null)
      }

      this.$set(args.model, args.key, value)
    }

    return args
  }
}
