var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"boosts-condition-view"},[_c('select-input',{staticClass:"mt-0",attrs:{"setup":{
      'func': _vm.sourceSetter,
      'args': {
        'model': _vm.model,
        'key': 'source',
        'prefix': _vm.sourcePrefix,
        'options': _vm.boostsConditionOptions,
        'clearable': false,
      }
    }}}),(_vm.model.source === _vm.BoostsSourceEnum.Custom)?_c('nested-content',[_c('text-input',{staticClass:"mt-0 mb-0",attrs:{"setup":{
        'func': _vm.customSetter,
        'args': {
          'model': _vm.model,
          'key': 'custom',
          'prefix': 'boosts_condition_source_',
          'validation': 'required|integer'
        }
      }}})],1):_vm._e(),_c('select-input',{staticClass:"mb-0",attrs:{"setup":{
      'func': _vm.sourceSetter,
      'args': {
        'model': _vm.model,
        'key': 'required',
        'prefix': 'boosts_condition_',
        'options': _vm.requiredConditionOptions,
        'clearable': false,
      }
    }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }